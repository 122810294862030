import React from 'react';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <section className="container not-found page-m-t page-m-b">
    <div className="row">
      <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-lg-offset-1">
        <h1>404 Page Not Found</h1>
        <p>
          The page you are looking for does not exist. It may have been moved,
          or removed altogether. Perhaps you can return back to the site’s
          homepage and see if you can find what you are looking for.
        </p>
        <Link to="/" className="button">
          Back to Home
        </Link>
      </div>
    </div>
  </section>
);

export default NotFoundPage;
